<template>
    <div class="mb-4" v-if="isPermission && isEditMember">
        <h6 class="pl-2 font-weight-bold">
            {{ $t('contact') }}
            <strong class="text-danger">{{ contactAlert }}</strong>
        </h6>
        <CCard>
            <CCardBody class="pb-0">
                <table style="width:100%" class="table">
                    <tr>
                        <td style="width:20%">
                            {{ $t('nameTaxpayer') }}
                            <b class="text-danger font-weight-normal">*</b>
                        </td>
                        <td>
                            <input id="editTaxAddressNameTaxpayer" style="border:none" type="text" class="form-control" :placeholder="$t('enterCustomerName')"
                                v-model="fullname" />
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('taxID') }}
                            <b class="text-danger font-weight-normal">*</b></td>
                        <td>
                            <input id="editTaxAddressTaxID" v-model="taxId" style="border:none" class="form-control" type="text"
                            :placeholder="$t('enterTaxIDNumber')" />
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #ECECEC;">
                        <td>
                            {{ $t('tel') }}
                        </td>
                        <td>
                            <input id="editTaxAddressTel" v-model="tel" style="border:none" type="text" class="form-control"
                            :placeholder="$t('enterTel')"/>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #ECECEC;">
                        <td>
                            {{ $t('branchName') }}
                        </td>
                        <td>
                            <input id="editTaxAddressBranchName" v-model="branchName" style="border:none" type="text" class="form-control"
                            :placeholder="$t('enterBranchname')"/>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #ECECEC;">
                        <td>
                            {{ $t('email') }}
                        </td>
                        <td>
                            <input id="editTaxAddressEmail" v-model="email" style="border:none" type="text" class="form-control"
                            :placeholder="$t('enterEmail')" />
                        </td>
                    </tr>
                </table>
            </CCardBody>
        </CCard>
        <h6 class="pl-2 font-weight-bold">
            {{ $t('txtTaxAddress') }}
            <b class="text-danger font-weight-normal">*</b>
            <strong class="text-danger">{{ addressAlert }}</strong>
        </h6>
        <CCard>
            <CCardBody>
                <div class="d-flex justify-content-between align-items-start">

                </div>
                <textarea id="editTaxAddressInvoiceAddress" rows="3" class="form-control" v-model="invoiceAddress"></textarea>
            </CCardBody>
        </CCard>

        <CRow class="mb-3">
            <CCol>
                <CButton id="editTaxAddressSaveButton" v-if="loadingButton == false" block color="success" class="px-4 text-white" @click="updateMember()">
                    {{ $t('save') }}
                </CButton>
                <CButton id="editTaxAddressSaveButtonLoading" v-else block color="success" class="px-4 text-white" @click="updateMember()">
                    <CSpinner color="white" size="sm" />{{ $t('save') }}
                </CButton>
            </CCol>
            <CCol>
                <CButton id="editTaxAddressCancelButton" block color="dark" class="px-4 text-white" @click="goback()">
                    {{ $t('cancel') }}
                </CButton>
            </CCol>
        </CRow>

    </div>
<div v-else id="editTaxAddressNoPermission">
    <access-data></access-data>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import permis from '@/util/permission'

export default {
    data() {
        return {
            calendarRender: 0,
            data: {},
            fullname: '',
            tel: null,
            email: '',
            popupModal: false,
            detailModal: false,
            deleteModal: false,
            footer: '',
            validateAlert: '',
            loadingButton: false,
            successAlert: '',
            contactAlert: '',
            addressAlert: '',
            deletedAt: null,
            errors: '',
            invoiceAddress: '',
            address: 0,
            oldaddress: '',
            taxId: '',
            branchName: '',
            id: '',
        }
    },
    computed: {
        ...mapGetters(['shops', 'users']),
        isPermission() {
            return permis.findPermissionRead('member', '/member/data')
        },
        isEditMember() {
            return permis.findPermissionEdit('member', '/member/data')
        },
        uid() {
            return `${localStorage.getItem('shopsUid')}`
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        objectId() {
            return this.$route.params.objectId
        },
    },
    created() {
        this.getAddress()
        this.getAddressByMemberObjectId()
    },
    methods: {
        getDateLocale() {
            if (this.$i18n.locale == 'en') {
                return { lang: 'en' }
            } else {
                return { lang: 'de' }
            }
        },
        editTaxAddress() {
            this.$router.push('/member/' + this.objectId + '/editmember/editTaxInvoiceAddress')
        },
        addAddress() {
            this.$router.push('/member/' + this.objectId + '/address')
        },
        validateContact() {
            if (!this.fullname) {
                this.contactAlert = this.$i18n.t('validateName')
                return false
            } else {
                if (!this.validTaxID(this.taxId)) {
                    this.contactAlert = this.$i18n.t('validateTaxID')
                } else {
                    this.contactAlert = ''
                    return true
                }
            }
        },
        validateAddress() {
            if (!this.invoiceAddress) {
                this.addressAlert = this.$i18n.t('validateAddress')
                return false
            } else {
                this.addressAlert = ''
                return true
            }
        },
        getAddress() {
            const uid = this.uid
            const objectId = this.objectId

            let url =
                '/api/v1.0/' + uid + '/Shop/getmemberbyobjectId/' + objectId + '/data'

            pos({
                method: 'get',
                url: url,
            })
                .then((res) => {
                    let doc = res.data.data.documents

                    if (doc) {
                        this.data = doc
                        this.tel = doc.telTax || doc.phone
                        this.email = doc.email
                        this.invoiceAddress = doc.address
                        this.fullname = doc.name
                        this.taxId = doc.taxID
                        this.branchName = doc.branchName
                        this.isMember = doc.isMember
                        this.id = doc.id
                    } else {
                        console.log('error')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        updateMember() {
            let checkcontact = this.validateContact()
            let checkaddress = this.validateAddress()

            if (checkcontact && checkaddress) {
                this.loadingButton = true
                const uid = this.uid

                let data = {
                    name: this.fullname,
                    telTax: this.tel,
                    email: this.email,
                    shopObjectId: this.shopObjectId,
                    objectId: this.objectId,
                    branchName: this.branchName,
                    address: this.invoiceAddress,
                    taxID: this.taxId,
                    id: this.id

                }
                let headers = { shopObjectId: this.shopObjectId }

                pos({
                    method: 'post',
                    url: '/api/v1.0/' + uid + '/Shop/updatemember/data',
                    data: data,
                    headers: headers,
                })
                    .then((res) => {
                        this.loadingButton = false
                        this.$router.push('/member/' + this.objectId + '/editmember')
                        
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },


        validTaxID(taxID) {
            const TAXIDREG = /^\d{13}$/;
            return TAXIDREG.test(taxID);
        },
        goback() {
            this.$router.push('/member/' + this.objectId + '/editmember')
        },
        getAddressByMemberObjectId() {
            const objectId = this.objectId
            let url = '/api/v1.0/address/getbymember/' + objectId

            pos({
                method: 'get',
                url: url,
            })
                .then((res) => {
                    this.address = res.data.data.length
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>